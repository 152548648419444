/* You can add global styles to this file, and also import other style files */
$gutter: 1rem; //for primeflex grid system

/* Layout */
@import "assets/layout/styles/layout/layout.scss";

/* PrimeNG */
@import "../node_modules/primeng/resources/primeng.min.css";
@import "../node_modules/primeflex/primeflex.scss";
@import "../node_modules/primeicons/primeicons.css";

/* Quill Text Editor for p-editor */
@import "../node_modules/quill/dist/quill.core.css";
@import "../node_modules/quill/dist/quill.snow.css";

/* Demos */
@import "assets/demo/styles/flags/flags.css";
@import "assets/demo/styles/badges.scss";
@import "assets/demo/styles/code.scss";

.layout-primary {
  --v-body-bg: white;
  --v-menuitem-text-color: black;
  --v-menuitem-text-color-secondary: black;
  --v-topbar-search-button-icon-color: black;
  --v-menuitem-hover-bg: #edf0f5;
  --surface-ground: #edf0f5;
  --text-color: black;
  --font-family: Alibaba-PuHuiTi, Lato, Helvetica, sans-serif;
}

body {
  font-family: Alibaba-PuHuiTi, Lato, Helvetica, sans-serif;
}

body a {
  color: var(--v-menuitem-text-color-secondary);
}

.text-grey {
  color: #999999 !important;
}

.text-black {
  color: black !important;
}

.text-red {
  color: #ef4444 !important;
}

.text-white {
  color: white !important;
}

.font-size-2xl {
  font-size: 1.75rem !important;
}

.padding-0_75 {
  padding: 0.75rem !important;
}

.layout-topbar {
  padding: 0 2rem 0 2rem !important;
}

.layout-menuitem-root-text {
  padding: 0.75rem 0 0.75rem 2rem !important;
}

.dashboard-title-avatar {
  width: 4rem !important;
  height: 4rem !important;
  border: 1px solid #239253;
}

.dashboard-percent-icon {
  width: 5rem !important;
  height: 5rem !important;
}

.dashboard-product-image img {
  width: 4rem;
  height: 4rem;
  border-radius: 10px;
}

.dashboard-product-image-2 img {
  width: 12rem;
  height: 12rem;
  border-radius: 10px;
}

.dashboard-product-image .p-image-preview {
  width: auto;
  height: auto;
}

.dashboard-product-image-2 .p-image-preview {
  width: auto;
  height: auto;
}

.border-radius-10 {
  border-radius: 10px;
}

.bg-white {
  background-color: white;
}

.bg-dark-blue {
  background: #4C63B6;
}

.bg-grey {
  background: var(--surface-ground);
}

.grey-border-bottom {
  border-bottom: 1px solid #f3f3f3;
}

.grey-border-bottom-2 {
  border-bottom: 1px solid #dee2e6;
}

.main-content {
  /*border-top: 1px solid #dee2e6;*/
}

.layout-sidebar .layout-menu-container {
  border-right: 1px solid #dee2e6;
}

.static-menu .child-menu {
  margin-left: 3rem;
}

.layout-topbar {
  min-height: 72px;
}

.p-button-success-2 {
  color: #ffffff;
  background: #27AB83;
  border: 1px solid #27AB83;
  padding: 0.75rem 1.25rem;
  font-size: 1rem;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
  border-radius: 6px;
}

.p-button-success-2:enabled:hover {
  background: #239a76;
  color: #ffffff;
  border-color: #239a76;
}

.p-button-success-2:enabled:active {
  background: #1f8969;
  color: #ffffff;
  border-color: #1f8969;
}

.p-datatable .p-datatable-header {
  border-top-width: 0 !important;
}

.product-image {
  width: 4rem;
  height: 4rem;
}

.product-image img {
  width: 100%;
  height: 100%;
}

.order-table {
  font-size: 0.85rem !important;
}

.order-table .p-datatable-thead > tr > th {
  font-weight: 600 !important;
  padding: 0.5rem !important;
}

.order-table .p-datatable-tbody > tr > td {
  padding: 0.5rem !important;
}

.order-table .p-datatable-tbody > tr > td input {
  font-size: 0.85rem;
  box-shadow: 0 3px 6px 1px rgba(0, 0, 0, 0.10) inset;
}

.my-overlaypanel-content .p-menu {
  border-width: 0 !important;
  width: auto;
}

.image-dialog .p-dialog-content {
  background-color: var(--surface-ground)!important;
  height: 100%;
}
